import axios from 'services/axios'

const getAppointmentStats = (): Promise<AppointmentStat> => {
	return axios.get('/stats').then(response => response.data.stats)
}

const getAppointmentById = (appointmentId: string): Promise<Appointment> => {
	return axios.get(`/appointments/${appointmentId}`).then(response => response.data)
}

const getAppointmentsByClientId = (clientId: string): Promise<Appointment[]> => {
	return axios.get(`/appointmentsbyClientId?id_client=${clientId}`).then(response => response.data)
}

const getAppointmentStatsByTime = (from: number, to: number): Promise<AppointmentStat> => {
	return axios.get(`/range/stats?from=${from}&to=${to}`).then(response => response.data.stats)
}

const getCompanyAppointmentsForEmployee = () => {
	return axios.get(`/employee/appointments`).then(response => response.data)
}

const getCompanyAppointments = (companyId: string) => {
	return axios.get(`/appointments?id_company=${companyId}`).then(response => response.data)
}

const assignDriverToAppointment = (
	appointmentId: string,
	driverId: string
): Promise<DocumentDetail> => {
	return axios
		.post('/appointments-assign-step1', { appointmentId, driverId })
		.then(response => response.data.data)
}

const sendPdfToDriver = (data: FormData) => {
	return axios
		.post('/appointments-assign-step2', data, {
			headers: { 'Content-Type': 'multipart/form-data' }
		})
		.then(response => response.data)
}

const getAppointmentQuestionsByService = (
	serviceId: string,
	group: 'questions'
): Promise<AppointmentQuestion[]> => {
	return axios.get(`/user/fields/${serviceId}?group=${group}`).then(response => response.data)
}

const getRevenueStatsByWeek = (): Promise<WeeklyRevenue[]> => {
	return axios.get('/revenue/stats/weekly').then(response => response.data)
}

const getRevenueStatsByYear = (year: number): Promise<MonthlyRevenue[]> => {
	return axios.get(`/revenue/stats/yearly?year=${year}`).then(response => response.data)
}

const getAppointmentSlots = (
	id: string,
	from: number,
	to: number,
	employeeId?: string
): Promise<{ from: number; to: number }[]> => {
	const params = new URLSearchParams({
		id_company: id,
		from: from.toString(),
		to: to.toString()
	})
	if (employeeId) {
		params.append('id_employee', employeeId)
	}

	return axios.get(`/slots/appointments?${params.toString()}`).then(response => response.data)
}

const getCompanyAppointmentsByTime = (
	companyId: string,
	from: number,
	to: number
): Promise<Appointment[]> => {
	return axios
		.get(`/time/appointments?id_company=${companyId}&from=${from}&to=${to}`)
		.then(response => response.data)
}

const createAppointment = (data: AppointmentForm) => {
	return axios.post('/appointments', data).then(response => response.data)
}

const updateAppointment = (appointmentId: string, data: { status: string }) => {
	return axios.put(`/appointments/${appointmentId}`, data).then(response => response.data)
}

const updateAppointmentSlots = (id: string, from: number, to: number) => {
	return axios.put(`/appointments/${id}`, { from, to }).then(response => response.data)
}

const updateAppointmentStatus = (id: string, status: string, driverId: string) => {
	return axios
		.put(`/appointment/status/${id}?status=${status}&id_driver=${driverId}`)
		.then(response => response.data)
}

const deleteAppointment = (id: string) => {
	return axios.delete(`/appointments/${id}`).then(response => response.data)
}

const appointmentService = {
	getAppointmentStats,
	getAppointmentById,
	getCompanyAppointmentsForEmployee,
	updateAppointment,
	deleteAppointment,
	getAppointmentsByClientId,
	getRevenueStatsByWeek,
	getRevenueStatsByYear,
	sendPdfToDriver,
	getAppointmentStatsByTime,
	assignDriverToAppointment,
	getCompanyAppointmentsByTime,
	getAppointmentQuestionsByService,
	getAppointmentSlots,
	getCompanyAppointments,
	updateAppointmentSlots,
	createAppointment,
	updateAppointmentStatus
}

export default appointmentService
