import axios from 'services/axios'

const getCompanyClients = (): Promise<User[]> => {
	return axios.get('clients').then(response => response.data)
}

const getClientById = (id: string): Promise<User> => {
	return axios.get(`/clients/${id}`).then(response => response.data)
}

const updateClient = (id: string, data: UserForm) => {
	return axios.put(`/clients/${id}`, data).then(response => response.data)
}

const getUserRelations = (id: string): Promise<RelatedPerson[]> => {
	return axios.get(`/relations/company/${id}`).then(response => response.data)
}

const createClient = (data: UserForm) => {
	return axios.post('/clients', data).then(response => {
		return response.data
	})
}

const updateUserProfile = (formData: FormData) => {
	return axios
		.post('/user/profile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then(response => response.data)
}

const updateUserPassword = (old_password: string, new_password: string) => {
	return axios
		.put('/auth/password/user', { old_password, new_password })
		.then(response => response.data)
}

const deleteUser = (id: string) => {
	return axios.delete(`/clients/${id}`).then(response => response.data)
}

const sendRegistrationEmail = (data: {
	name: string
	phone: string
	email: string
	birthDate: string
	password: string
}) => {
	return axios.post('/auth/client/sendUserData', data).then(res => res.data)
}

const userService = {
	getCompanyClients,
	getUserRelations,
	getClientById,
	deleteUser,
	updateClient,
	updateUserProfile,
	sendRegistrationEmail,
	updateUserPassword,
	createClient
}

export default userService
