import { ChevronLeftIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'
import { DateTime } from 'luxon'

import { Modal } from 'components/app/modal'
import { CalendarEvent } from 'components/calendar'
import { EventDetail } from 'components/calendar/event-detail'
import AllSlots from 'components/calendar/week-calendar/all-slots'
import EventCard from 'components/calendar/week-calendar/event-card'
import { useComponentVisible } from 'hooks/useComponentVisible'
import useIsMobile from 'hooks/useIsMobile'
import { getAppLang } from 'utils/language'

import '../scroll-bar.css'

interface ViewProps {
	currentDate: DateTime
	events: CalendarEvent[]
	onDateClick: (date: DateTime) => void
	refetch: () => void
	onAppointmentClick: (appointmentId: string) => void
	onEventClick: (eventId: string) => void
	onUpdate: () => void
}

const CELL_HEIGHT = 64
const OFFSET_HEIGHT = 64

const WeekCalendar = ({
	currentDate,
	events,
	refetch,
	onAppointmentClick,
	onEventClick,
	onDateClick,
	onUpdate
}: ViewProps) => {
	const { t } = useTranslation()
	const [isDragging, setIsDragging] = useState(false)
	const [openNotePopup, setOpenNotePopup] = useState(false)
	const [openAppointmentPopup, setOpenAppointmentPopup] = useState(false)

	const timeRef = useRef<HTMLDivElement>(null)

	const startOfWeek = currentDate.startOf('week')
	const endOfWeek = currentDate.endOf('week')
	const containerRef = useRef<HTMLDivElement>(null)
	const containerNav = useRef<HTMLDivElement>(null)
	const containerOffset = useRef<HTMLDivElement>(null)

	const isMobile = useIsMobile()

	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
	const [selectedEvent, setSelectedEvent] = useState<CalendarEvent>()

	useEffect(() => {
		if (containerRef.current) {
			const now = DateTime.now()
			const minutesSinceMidnight = now.hour * 60 + now.minute
			containerRef.current.scrollTop =
				(containerRef.current.scrollHeight * minutesSinceMidnight) / 1440
		}
	}, [])

	useEffect(() => {
		if (timeRef) {
			const element = timeRef.current
			if (element) {
				const offset = isMobile ? 250 : 250
				const elementPosition = element.getBoundingClientRect().top
				const offsetPosition = elementPosition + window.pageYOffset - offset
				window.scrollTo({
					top: offsetPosition,
					behavior: 'smooth'
				})
			}
		}
	}, [timeRef, isMobile])

	const days = []
	let day = startOfWeek
	while (day <= endOfWeek) {
		days.push(day)
		day = day.plus({ days: 1 })
	}

	const calculateEventPosition = (
		event: CalendarEvent,
		dayIndex: number,
		totalEvents: number,
		eventIndex: number,
		isOverlapping: boolean
	) => {
		const eventStart = DateTime.fromISO(event.startDateTime)
		const eventEnd = DateTime.fromISO(event.endDateTime)
		const startMinutes = eventStart.hour * 60 + eventStart.minute
		const endMinutes = eventEnd.hour * 60 + eventEnd.minute
		const top = (startMinutes * CELL_HEIGHT) / 60
		const height = ((endMinutes - startMinutes) * CELL_HEIGHT) / 60
		const left = isOverlapping
			? `${(dayIndex * 100) / 7 + (100 / 7 / totalEvents) * eventIndex}%`
			: `${(dayIndex * 100) / 7}%`
		const width = isOverlapping ? `calc(100% / 7 / ${totalEvents} + 1px)` : `calc(100% / 7 + 2px)`

		return { top, height, left, width }
	}

	const groupEventsByDayAndColumn = (events: any[], day: DateTime) => {
		const dayEvents = events.filter(event =>
			DateTime.fromISO(event.startDateTime).hasSame(day, 'day')
		)
		const sortedEvents = dayEvents.sort((a, b) => {
			const aStart = DateTime.fromISO(a.startDateTime)
			const bStart = DateTime.fromISO(b.startDateTime)
			return aStart.valueOf() - bStart.valueOf()
		})

		const columns: CalendarEvent[][] = []
		sortedEvents.forEach(event => {
			let placed = false
			for (let i = 0; i < columns.length; i++) {
				if (
					!columns[i].some(e => {
						const eStart = DateTime.fromISO(e.startDateTime)
						const eEnd = DateTime.fromISO(e.endDateTime)
						const eventStart = DateTime.fromISO(event.startDateTime)
						const eventEnd = DateTime.fromISO(event.endDateTime)
						return eventStart < eEnd && eventEnd > eStart
					})
				) {
					columns[i].push(event)
					event.column = i
					placed = true
					break
				}
			}
			if (!placed) {
				columns.push([event])
				event.column = columns.length - 1
			}
		})

		return columns.flat()
	}

	const calculateOverlappingEvents = (events: CalendarEvent[]) => {
		const sortedEvents = events.sort(
			(a, b) =>
				DateTime.fromISO(a.startDateTime).toMillis() - DateTime.fromISO(b.startDateTime).toMillis()
		)

		const overlappingGroups: CalendarEvent[][] = []
		let currentGroup: CalendarEvent[] = []
		let latestEndTime: DateTime | null = null

		for (let i = 0; i < sortedEvents.length; i++) {
			const currentEvent = sortedEvents[i]
			const currentStartTime = DateTime.fromISO(currentEvent.startDateTime)
			const currentEndTime = DateTime.fromISO(currentEvent.endDateTime)

			if (currentGroup.length === 0) {
				currentGroup.push(currentEvent)
				latestEndTime = currentEndTime
			} else {
				if (currentStartTime < (latestEndTime as DateTime)) {
					currentGroup.push(currentEvent)
					if (currentEndTime > (latestEndTime as DateTime)) {
						latestEndTime = currentEndTime
					}
				} else {
					overlappingGroups.push(currentGroup)
					currentGroup = [currentEvent]
					latestEndTime = currentEndTime
				}
			}
		}

		if (currentGroup.length > 0) {
			overlappingGroups.push(currentGroup)
		}

		return overlappingGroups
	}

	const handleEventSelection = (event: CalendarEvent, date: string) => {
		setSelectedEvent(event)
		setIsComponentVisible(true)
	}

	const renderAllDayEvents = (events: CalendarEvent[], day: DateTime) => {
		const dayEvents = events.filter(
			event => event.allDay && DateTime.fromISO(event.startDateTime).hasSame(day, 'day')
		)
		const totalEvents = dayEvents.length
		return dayEvents.map((event, index) => (
			<div
				key={event.id}
				onClick={() => handleEventSelection(event, day.toISODate() as string)}
				className="absolute cursor-pointer scroll-container gap-1 border-l-[1.2px] md:border-l-[3px] max-md:pt-1 max-md:pb-[1.6px] max-md:px-1 md:pl-2 md:pr-1 md:py-1.5 flex flex-col"
				style={{
					top: 0,
					height: `${CELL_HEIGHT}px`,
					left: `${
						(day.diff(startOfWeek, 'days').days * 100) / 7 + (100 / 7 / totalEvents) * index
					}%`,
					width: `calc(100% / 7 / ${totalEvents})`,
					background: event.color + '10',
					borderColor: event.color,
					zIndex: 40
				}}>
				<p className="text-primary font-semibold text-[10px] leading-[10px] md:text-sm md:leading-[18px]">
					{event.name}
				</p>
				<time
					dateTime={event.startDateTime}
					className="flex whitespace-nowrap items-center gap-[2.4px] md:gap-1.5 text-[#757575] text-[8px] md:text-xs">
					{t('calendar.eventDetails.labels.allDay')}
					<svg
						width="4"
						height="5"
						viewBox="0 0 4 5"
						fill="none"
						className="shrink-0"
						xmlns="http://www.w3.org/2000/svg">
						<circle cx="2" cy="2.55078" r="2" fill="#7F9AB2" />
					</svg>
					{(event as any).type === 'appointment'
						? (event as any).id_client.fname + ' ' + (event as any).id_client.lname
						: (event as any).type === 'event'
							? (event as any).addedBy.fname + ' ' + (event as any).addedBy.lname
							: (event as any).assignedBy.fname + ' ' + (event as any).assignedBy.lname}
				</time>
			</div>
		))
	}

	useEffect(() => {
		// Set the container scroll position based on the current time.
		const currentMinute = new Date().getHours() * 60
		if (containerRef.current && containerNav.current && containerOffset.current) {
			containerRef.current.scrollTop =
				((containerRef.current.scrollHeight -
					containerNav.current.offsetHeight -
					containerOffset.current.offsetHeight) *
					currentMinute) /
				1440
		}
	}, [])

	return (
		<div className="flex h-full w-full flex-col">
			<div ref={ref}>
				{((isComponentVisible && selectedEvent && !isMobile) ||
					openNotePopup ||
					openAppointmentPopup) && (
					<EventDetail
						{...selectedEvent}
						openNotePopup={openNotePopup}
						setOpenNotePopup={(value: boolean) => {
							setOpenNotePopup(value)
							setIsComponentVisible(true)
						}}
						openAppointmentPopup={openAppointmentPopup}
						setOpenAppointmentPopup={(value: boolean) => {
							setOpenAppointmentPopup(value)
							setIsComponentVisible(true)
						}}
						refetch={() => {
							setSelectedEvent(undefined)
							setIsComponentVisible(false)
							refetch()
						}}
						onAppointmentClick={(id: string) => {
							setIsComponentVisible(false)
							onAppointmentClick(id)
						}}
						onEventClick={(id: string) => {
							setIsComponentVisible(false)
							onEventClick(id)
						}}
					/>
				)}
			</div>
			{selectedEvent && isMobile && (
				<Modal isFullHeight width="w-[900px]" showCrossIcon={false} noPadding>
					<div className="sticky max-md:grid max-md:grid-flow-col max-md:auto-cols-auto md:flex items-center md:justify-between top-0 z-10 bg-white max-md:p-5 md:px-8 py-4 border-b border-border">
						<ChevronLeftIcon
							onClick={() => setSelectedEvent(undefined)}
							className="w-3 h-3 md:hidden cursor-pointer"
						/>
						<h1 className="text-primary text-[20px] max-md:text-center whitespace-nowrap max-md:text-sm font-domine font-bold">
							{t('calendar.eventDetails.labels.eventDetails')}
						</h1>
						<div className="md:hidden" />
						<XMarkIcon
							onClick={() => setSelectedEvent(undefined)}
							className="text-primary max-md:hidden bg-white h-6 w-6 cursor-pointer"
						/>
					</div>
					<div className="p-5">
						<EventDetail
							{...selectedEvent}
							openNotePopup={openNotePopup}
							setOpenNotePopup={setOpenNotePopup}
							openAppointmentPopup={openAppointmentPopup}
							setOpenAppointmentPopup={(value: boolean) => {
								setOpenAppointmentPopup(value)
								setIsComponentVisible(true)
							}}
							refetch={() => {
								setSelectedEvent(undefined)
								refetch()
							}}
							onAppointmentClick={(id: string) => {
								setIsComponentVisible(false)
								onAppointmentClick(id)
							}}
							onEventClick={(id: string) => {
								setIsComponentVisible(false)
								onEventClick(id)
							}}
						/>
					</div>
				</Modal>
			)}
			{isComponentVisible && !isMobile && (
				<div className="fixed inset-0 bg-[#00000099] transition-opacity z-[99]" />
			)}
			<div ref={containerRef} className="isolate flex flex-auto flex-col bg-white">
				<div
					style={{ width: '100%' }}
					className="flex max-w-full flex-none gap-5 flex-col sm:max-w-none md:max-w-full">
					<div
						style={{ boxShadow: '0px 4px 24px 0px #1232581A' }}
						ref={containerNav}
						className="sticky top-[113px] md:top-[81px] z-[999] self-start w-full flex-none bg-[#E5EAF0] rounded-lg py-2 border border-[#F5F7FA] shadow ring-1 ring-black ring-opacity-5 sm:pr-8">
						<div className="-mr-px grid-cols-7 text-sm leading-6 text-black grid">
							<div className="col-end-1 w-14" />
							{days.map(day => (
								<div
									key={day.toISODate()}
									className="flex items-center justify-center max-md:py-1.5 max-md:px-3 md:py-3">
									<span
										onClick={() => onDateClick(day)}
										className={clsx(
											'flex cursor-pointer text-[10px] flex-col-reverse md:gap-1 items-center md:text-sm',
											{
												'text-primary-light': day.hasSame(DateTime.now(), 'day')
											}
										)}>
										{isMobile
											? day.setLocale(getAppLang()).toFormat('ccc')
											: day.setLocale(getAppLang()).toFormat('cccc')}
										<span
											className={clsx(
												'text-center text-[11px] md:text-[18px] font-semibold',
												day.hasSame(DateTime.now(), 'day') ? 'text-primary-light' : 'text-black'
											)}>
											{day.toFormat('d')}
										</span>
									</span>
								</div>
							))}
						</div>
					</div>

					<div
						style={{ boxShadow: '0px 4px 24px 0px #1232581A' }}
						className="flex sticky top-[190px] md:top-[165px] z-50 bg-white border border-[#CFD8DC80] rounded-xl flex-auto">
						<div className="sticky left-0 z-10 w-14 rounded-tl-xl rounded-bl-xl flex-none bg-white ring-1 ring-gray-100">
							<div className="h-16 bg-white flex items-center justify-center text-xs text-gray-500">
								{t('calendar.labels.allDays')}
							</div>
						</div>
						<div className="grid relative flex-auto grid-cols-7 grid-rows-1 divide-x divide-gray-200">
							{/* Horizontal lines */}
							<div
								className="col-start-1 relative z-10 col-end-8 row-start-1 grid divide-y divide-gray-200 pointer-events-none"
								style={{ gridTemplateRows: 'repeat(1, minmax(4rem, 1fr))' }}>
								<div className="h-16"></div>
							</div>

							{/* Vertical lines */}
							<div className="col-start-1 relative col-end-8 row-start-1 grid grid-cols-7 divide-x divide-gray-200 pointer-events-none">
								{Array.from({ length: 7 }, (_, dayIndex) => (
									<div
										key={dayIndex}
										className={clsx('col-span-1', {
											'bg-gray-50': dayIndex === 5 || dayIndex === 6
										})}></div>
								))}
							</div>

							{/* All-Day Events */}
							<div
								className="col-start-1 col-end-8 row-start-1 grid grid-cols-7"
								style={{ height: `${CELL_HEIGHT}px` }}>
								{days.map(day => renderAllDayEvents(events, day))}
							</div>
						</div>
					</div>

					<div
						style={{ boxShadow: '0px 4px 24px 0px #1232581A' }}
						className="flex border overflow-hidden border-[#CFD8DC80] rounded-xl flex-auto">
						<div className="sticky left-0 z-10 w-14 rounded-tl-xl rounded-bl-xl flex-none bg-white ring-1 ring-gray-100">
							{Array.from({ length: 24 }).map((_, index) => (
								<div
									ref={
										DateTime.fromObject({ hour: index - 1 }).toFormat('ha') === '6AM'
											? timeRef
											: null
									}
									key={index}
									className="h-16 flex items-center justify-center text-xs text-gray-500">
									{DateTime.fromObject({ hour: index }).toFormat('HH:mm')}
								</div>
							))}
						</div>
						<div className="grid relative flex-auto grid-cols-7 grid-rows-1 divide-x divide-gray-200">
							{/* Horizontal lines */}
							<div
								className="col-start-1 relative z-10 col-end-8 row-start-1 grid divide-y divide-gray-200 pointer-events-none"
								style={{ gridTemplateRows: 'repeat(24, minmax(4rem, 1fr))' }}>
								{Array.from({ length: 24 }).map((_, hour) => (
									<div key={hour} className="h-16"></div>
								))}
							</div>

							{/* Vertical lines */}
							<div className="col-start-1 relative z-10 col-end-8 row-start-1 grid grid-cols-7 divide-x divide-gray-200 pointer-events-none">
								{Array.from({ length: 7 }, (_, dayIndex) => (
									<div key={dayIndex} className="col-span-1"></div>
								))}
							</div>

							<AllSlots days={days} onUpdate={onUpdate} />

							{/* Timed Events */}
							<ol className="col-start-1 col-end-2 row-start-2 grid grid-cols-1 sm:grid-cols-7 sm:pr-8">
								{days.map((day, dayIndex) => {
									const dayEvents = groupEventsByDayAndColumn(
										events.filter(event => !event.allDay),
										day
									)

									const overlapGroups = calculateOverlappingEvents(dayEvents)
									return (
										<div
											key={day.toISODate()}
											className="col-start-auto col-end-auto row-start-1 grid grid-rows-24">
											{overlapGroups.map(group => {
												return group.map((event, eventIndex) => {
													return (
														<EventCard
															event={event}
															calculateEventPosition={calculateEventPosition}
															totalEvents={group.length}
															key={event.id}
															handleEventSelection={handleEventSelection}
															dayIndex={dayIndex}
															eventIndex={eventIndex}
															onUpdate={onUpdate}
															day={day}
															isDragging={isDragging}
															setIsDragging={setIsDragging}
														/>
													)
												})
											})}
										</div>
									)
								})}
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default WeekCalendar
