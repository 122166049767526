import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { toast } from 'react-toastify'

import clsx from 'clsx'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Input } from 'components/inputs/input'
import { RadioInput } from 'components/inputs/radio'
import { Select } from 'components/inputs/select'
import { useAppSelector } from 'hooks'
import userService from 'services/user-service'
import { getTKey } from 'utils/language'

interface CreateClientProps {
	onCreation: () => void
	onCancel: () => void
	clientId?: string
	isCalendarForm?: boolean
	className?: string
	setCreatedClient?: (id: string) => void
}

export const CreateClient = ({
	onCreation,
	onCancel,
	clientId,
	isCalendarForm,
	className,
	setCreatedClient
}: CreateClientProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('clients.create')

	const auth = useAppSelector(state => state.auth)

	const [isLoading, setIsLoading] = useState(false)

	const schema = yup.object<UserForm>().shape({
		fname: yup.string().required(t(tKey('errors.firstName'))),
		lname: yup.string().required(t(tKey('errors.lastName'))),
		gender: yup.string().required(t(tKey('errors.gender'))),
		disability: yup.string().notRequired(),
		email: yup
			.string()
			.notRequired()
			.email(t(tKey('errors.validEmail'))),
		mobile: yup
			.string()
			.notRequired()
			.test('is-valid-length', t(tKey('errors.maxPhone')), function (value) {
				if (!value) {
					return true
				}
				return value.length >= 11 && value.length <= 13
			}),
		telephone: yup
			.string()
			.notRequired()
			.test('is-valid-length', t(tKey('errors.maxPhone')), function (value) {
				if (!value) {
					return true
				}
				return value.length >= 11 && value.length <= 13
			}),
		emergencyContact: yup
			.string()
			.notRequired()
			.test('is-valid-length', t(tKey('errors.maxPhone')), function (value) {
				if (!value) {
					return true
				}
				return value.length >= 11 && value.length <= 13
			}),
		dob: yup
			.date()
			.transform((value, originalValue) => {
				return originalValue === '' ? undefined : value
			})
			.notRequired()
			.max(new Date(Date.now() - 18 * 365 * 24 * 60 * 60 * 1000), t(tKey('errors.dobAge'))),
		address: yup.string().notRequired(),
		city: yup.string().notRequired(),
		zipcode: yup.string().notRequired(),
		is_active: yup.string().notRequired()
	})

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
		control
	} = useForm<UserForm>({
		resolver: yupResolver(schema as any),
		mode: 'all'
	})

	useEffect(() => {
		if (clientId) {
			userService.getClientById(clientId).then(res =>
				reset({
					...res,
					is_active: res.is_active ? 'aktiv' : ('inaktiv' as any),
					...(res.dob && {
						dob: DateTime.fromMillis(Number(res.dob)).toFormat('yyyy-LL-dd') as any
					})
				})
			)
		}
	}, [clientId])

	const onSubmit = (data: any) => {
		setIsLoading(true)
		const payload: UserForm = {
			...data,
			is_active: data.is_active === 'aktiv',
			companyId: auth.companyId,
			created: DateTime.now().toMillis(),
			...(data.dob && { dob: DateTime.fromJSDate(data.dob).toMillis() })
		}
		if (!!clientId) {
			userService
				.updateClient(clientId, payload)
				.then(res => {
					toast.success(t(tKey('toast.clientUpdate')))
					setCreatedClient?.(res._id)
					onCreation()
				})
				.catch(error => {
					if (error?.response?.data?.message) {
						return toast.error(error?.response?.data?.message)
					}
					toast.error(t(tKey('toast.clientError')))
				})
				.finally(() => setIsLoading(false))
		} else {
			userService
				.createClient(payload)
				.then(res => {
					toast.success(t(tKey('toast.clientSuccess')))
					setCreatedClient?.(res._id)
					onCreation()
				})
				.catch(error => {
					if (error?.response?.data?.message) {
						return toast.error(error?.response?.data?.message)
					}
					toast.error(t(tKey('toast.clientError')))
				})
				.finally(() => setIsLoading(false))
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={clsx('md:px-8 py-6 max-md:px-5', className)}>
			<div className="flex flex-col gap-y-5">
				<div className="flex justify-between items-center">
					<h2 className="text-primary font-domine font-bold text-sm md:text-lg">
						{t(tKey('titles.basicInfo'))}
					</h2>
				</div>
				<Controller
					control={control}
					name="gender"
					render={({ field: { onChange, value } }) => (
						<div className="flex flex-col">
							<div className="flex flex-row gap-x-3">
								<RadioInput
									labelText={t(tKey('labels.male'))}
									onChange={onChange}
									name="gender"
									register={register}
									value="male"
									checked={value === 'male'}
								/>
								<RadioInput
									labelText={t(tKey('labels.female'))}
									onChange={onChange}
									register={register}
									name="gender"
									value="female"
									checked={value === 'female'}
								/>
							</div>
							{errors?.gender && (
								<p className="text-xs text-red-500 mt-1">{errors.gender.message}</p>
							)}
						</div>
					)}
				/>
				<div
					className={clsx('flex flex-col gap-y-5 md:flex-row gap-x-5', {
						'!flex-col': isCalendarForm
					})}>
					<Input
						register={register}
						errors={errors}
						name="fname"
						labelText={t(tKey('labels.firstName'))}
					/>
					<Input
						register={register}
						errors={errors}
						name="lname"
						labelText={t(tKey('labels.lastName'))}
					/>
				</div>
				<div
					className={clsx(
						'grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5',
						{ '!grid-cols-1 !grid-flow-row': isCalendarForm }
					)}>
					<Input register={register} errors={errors} name="email" labelText="Email" />
					<Input
						name="disability"
						register={register}
						errors={errors}
						labelText={t(tKey('labels.disability'))}
					/>
				</div>
				<div
					className={clsx(
						'grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5',
						{ '!grid-cols-1 !grid-flow-row': isCalendarForm }
					)}>
					<Input
						name="dob"
						register={register}
						errors={errors}
						labelText={t(tKey('labels.dob'))}
						type="date"
					/>
					<Controller
						control={control}
						name={'mobile'}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<div className="flex flex-col">
								<div className="relative overflow-visible rounded-md">
									<label
										htmlFor="name"
										className="absolute -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
										{t(tKey('labels.mobile'))}
									</label>
									<PhoneInput
										numberInputProps={{
											className:
												'w-full rounded font-normal pl-4 py-3 bg-white focus:ring-0 border-0 focus:border-0 text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none text-md'
										}}
										defaultCountry="CH"
										value={value}
										error={error}
										onChange={onChange}
									/>
								</div>
								{errors?.mobile && (
									<p className="text-xs text-red-500 mt-1">{errors.mobile.message as string}</p>
								)}
							</div>
						)}
					/>
				</div>

				<div
					className={clsx(
						'grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5',
						{ '!grid-cols-1 !grid-flow-row': isCalendarForm }
					)}>
					<Controller
						control={control}
						name={'telephone'}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<div className="flex flex-col">
								<div className="relative overflow-visible rounded-md">
									<label
										htmlFor="name"
										className="absolute -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
										{t(tKey('labels.telephone'))}
									</label>
									<PhoneInput
										numberInputProps={{
											className:
												'w-full rounded font-normal pl-4 py-3 bg-white focus:ring-0 border-0 focus:border-0 text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none text-md'
										}}
										defaultCountry="CH"
										value={value}
										error={error}
										onChange={onChange}
									/>
								</div>
								{errors?.telephone && (
									<p className="text-xs text-red-500 mt-1">{errors.telephone.message as string}</p>
								)}
							</div>
						)}
					/>
					<Controller
						control={control}
						name={'emergencyContact'}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<div className="flex flex-col">
								<div className="relative overflow-visible rounded-md">
									<label
										htmlFor="name"
										className="absolute -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
										{t(tKey('labels.emergencyContact'))}
									</label>
									<PhoneInput
										numberInputProps={{
											className:
												'w-full rounded font-normal pl-4 py-3 bg-white focus:ring-0 border-0 focus:border-0 text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none text-md'
										}}
										defaultCountry="CH"
										value={value}
										error={error}
										onChange={onChange}
									/>
								</div>
								{errors?.emergencyContact && (
									<p className="text-xs text-red-500 mt-1">
										{errors.emergencyContact.message as string}
									</p>
								)}
							</div>
						)}
					/>
				</div>

				<div className="flex flex-col gap-y-6 mt-5">
					<h2 className="text-primary font-domine font-bold text-sm md:text-lg">
						{t(tKey('titles.locationInfo'))}
					</h2>
					<div className="flex flex-col gap-y-5">
						<div
							className={clsx('flex flex-col gap-y-5 md:flex-row gap-x-5', {
								'!flex-col': isCalendarForm
							})}>
							<Input
								register={register}
								errors={errors}
								labelText={t(tKey('labels.location'))}
								name="address"
							/>
							<Input
								register={register}
								errors={errors}
								labelText={t(tKey('labels.zipCode'))}
								name="zipcode"
							/>
						</div>
						<div
							className={clsx(
								'grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5',
								{ '!grid-cols-1 !grid-flow-row': isCalendarForm }
							)}>
							<Input
								register={register}
								errors={errors}
								labelText={t(tKey('labels.city'))}
								name="city"
							/>
							<Select
								labelText={t(tKey('labels.status'))}
								name="is_active"
								register={register}
								errors={errors}>
								<option value="aktiv">{t(tKey('labels.active'))}</option>
								<option value="inaktiv">{t(tKey('labels.inActive'))}</option>
							</Select>
						</div>
					</div>

					<div className="flex gap-x-6 justify-end">
						<button type="button" onClick={onCancel} className="text-primary font-bold text-sm">
							{t(tKey('buttons.cancel'))}
						</button>
						<Button disabled={isLoading} className="text-sm font-bold">
							{isLoading ? (
								<div className="flex items-center justify-center gap-x-5">
									<Spinner />
									<span className="animate-pulse whitespace-nowrap">
										{t(tKey('buttons.pleaseWait'))}
									</span>
								</div>
							) : (
								<span>{!!clientId ? t(tKey('buttons.update')) : t(tKey('buttons.save'))}</span>
							)}
						</Button>
					</div>
				</div>
			</div>
		</form>
	)
}
