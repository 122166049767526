import { Combobox, Transition } from '@headlessui/react'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import { pdf } from '@react-pdf/renderer'
import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Table } from 'components/app/table'
import { Input } from 'components/inputs/input'
import { Select } from 'components/inputs/select'
import { useAppSelector } from 'hooks'
import { DateTime } from 'luxon'
import invoiceService from 'services/invoice-service'
import userService from 'services/user-service'
import { getAppLang, getTKey } from 'utils/language'
import { InvoiceDocument } from './pdf'

interface CreateInvoiceProps {
	className?: string
	onCancel: () => void
}

export const CreateInvoice = ({ onCancel, className }: CreateInvoiceProps) => {
	const { t } = useTranslation()

	const tKey = getTKey('invoices.create')

	const appLanguage = getAppLang()

	const auth = useAppSelector(state => state.auth)

	const [isLoading, setIsLoading] = useState(false)
	const [invoices, setInvoices] = useState<Invoice[]>([])
	const [selectedDuration, setSelectedDuration] = useState('')
	const [selectedRange, setSelectedRange] = useState({
		from: '',
		to: ''
	})
	const [users, setUsers] = useState<User[]>([])
	const [selectedUser, setSelectedUser] = useState<string>()
	const [query, setQuery] = useState('')
	const [selectedInvoices, setSelectedInvoices] = useState<Invoice[]>([])

	useEffect(() => {
		invoiceService.getInvoices().then(res => setInvoices(res))
		userService.getCompanyClients().then(res => setUsers(res))
	}, [])

	const generateInvoice = (event: React.FormEvent) => {
		event.preventDefault()
		let selectedPeriod: { from: string; to: string } | undefined
		if (selectedDuration === 'month') {
			selectedPeriod = {
				from: DateTime.now().startOf('month').toFormat('dd.LL.yyyy'),
				to: DateTime.now().endOf('month').toFormat('dd.LL.yyyy')
			}
		} else if (selectedDuration === 'week') {
			selectedPeriod = {
				from: DateTime.now().startOf('week').toFormat('dd.LL.yyyy'),
				to: DateTime.now().endOf('week').toFormat('dd.LL.yyyy')
			}
		} else if (selectedDuration === 'custom') {
			selectedPeriod =
				selectedRange.from && selectedRange.to
					? {
							from: DateTime.fromISO(selectedRange.from).toFormat('dd.LL.yyyy'),
							to: DateTime.fromISO(selectedRange.to).toFormat('dd.LL.yyyy')
						}
					: undefined
		}

		const generatePdf = async () => {
			setIsLoading(true)
			const blob = await pdf(
				<InvoiceDocument auth={auth} invoices={selectedInvoices} selectedRange={selectedPeriod} />
			).toBlob()
			const url = URL.createObjectURL(blob)

			const fileName = selectedPeriod
				? `${selectedPeriod.from + '_' + selectedPeriod.to}_invoice.pdf`
				: `${DateTime.now().toFormat('dd.LL.yyyy HH:mm')}_invoice.pdf`
			const link = document.createElement('a')
			link.href = url
			link.download = `${fileName}.pdf`
			link.target = '_blank'

			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
			setIsLoading(false)
		}
		generatePdf()
	}

	const filteredAppointments = useMemo(() => {
		return invoices
			.map((invoice, index) => ({ id: index + 1, ...invoice }))
			.filter(invoice => (selectedUser ? invoice.id_client._id === selectedUser : true))
			.filter(invoice => {
				const appointmentDate = DateTime.fromMillis(Number(invoice.id_booking?.appointment_time))

				if (selectedDuration === 'month') {
					return appointmentDate.hasSame(DateTime.now(), 'month')
				} else if (selectedDuration === 'week') {
					return appointmentDate.hasSame(DateTime.now(), 'week')
				} else if (selectedDuration === 'custom' && selectedRange.from && selectedRange.to) {
					const fromDate = DateTime.fromISO(selectedRange.from)
					const toDate = DateTime.fromISO(selectedRange.to)
					return appointmentDate >= fromDate && appointmentDate <= toDate
				}

				return true
			})
	}, [invoices, selectedDuration, selectedUser, selectedRange])

	const filteredOptions = useMemo(() => {
		if (users) {
			return users.filter(user => {
				const fname = user.fname ?? ''
				const lname = user.lname ?? ''
				const name = fname + ' ' + lname
				return (
					query === '' ||
					name
						.toLowerCase()
						.replace(/\s+/g, '')
						.includes(query.toLowerCase().replace(/\s+/g, '')) ||
					DateTime.fromMillis(Number(user.dob))
						.toFormat('dd.LL.yyyy')
						.toLowerCase()
						.includes(query.toLowerCase()) ||
					user.city
						?.toLowerCase()
						.replace(/\s+/g, '')
						.includes(query.toLowerCase().replace(/\s+/g, '')) ||
					user.zipcode
						?.toLowerCase()
						.replace(/\s+/g, '')
						.includes(query.toLowerCase().replace(/\s+/g, ''))
				)
			})
		} else {
			return users
		}
	}, [users, query])

	const columns = [
		{
			id: 'select',
			header: ({ table }: any) => (
				<input
					type="checkbox"
					className="p-2 appearance-none rounded text-main focus:outline-none focus:ring-transparent border-[#D3E3F1] text-primary-light text-base"
					checked={
						filteredAppointments.length > 0 &&
						selectedInvoices.length === filteredAppointments.length
					}
					name="select-all"
					onChange={event => {
						if (selectedInvoices.length === filteredAppointments.length) {
							setSelectedInvoices([])
						} else {
							setSelectedInvoices(filteredAppointments)
						}
					}}
					aria-label="Select all"
				/>
			),
			cell: (data: Cell) => (
				<input
					type="checkbox"
					className="p-2 appearance-none rounded text-main focus:outline-none focus:ring-transparent border-[#D3E3F1] text-primary-light text-base"
					name="select-single"
					checked={selectedInvoices.includes(data.row.original)}
					onChange={event => {
						if (selectedInvoices.includes(data.row.original)) {
							setSelectedInvoices(selectedInvoices.filter(invoice => invoice !== data.row.original))
						} else {
							setSelectedInvoices([...selectedInvoices, data.row.original])
						}
					}}
					aria-label="Select row"
				/>
			),
			enableSorting: false,
			enableHiding: false
		},
		{
			header: 'Id',
			accessorKey: 'id'
		},
		{
			header: t('invoices.tables.destinationName'),
			accessorKey: 'id_booking.destination.destination_name'
		},
		{
			header: t('invoices.tables.clientName'),
			accessorFn: (row: Invoice) => `${row.id_client.fname} ${row.id_client.lname}`
		},
		{
			header: t('invoices.tables.driverName'),
			accessorFn: (row: Invoice) => `${row.id_driver.fname} ${row.id_driver.lname}`
		},
		{
			header: t('invoices.tables.distance'),
			accessorKey: 'id_booking.destination.distance',
			cell: (data: Cell) => <p>{data.getValue()} km</p>
		},
		{
			header: t('invoices.tables.cost'),
			accessorKey: 'id_booking.destination.cost',
			cell: (data: Cell) => <p>CHF {data.getValue()?.toFixed(2)}</p>
		},
		{
			header: t('invoices.tables.date'),
			accessorKey: 'id_booking.appointment_time',
			cell: (date: Cell) => (
				<div className="flex flex-col items-center">
					<span>
						{DateTime.fromMillis(date.getValue()).setLocale(appLanguage).toFormat('dd.LL.yyyy')}
					</span>
					<span>
						{DateTime.fromMillis(date.getValue()).setLocale(appLanguage).toFormat('HH:mm')}
					</span>
				</div>
			)
		}
	]

	return (
		<form onSubmit={generateInvoice} className={clsx('max-md:px-5 md:px-8 py-6', className)}>
			<div className="grid grid-cols-2 gap-y-3 md:flex items-center justify-end gap-x-2.5 mb-8">
				<Combobox
					value={selectedUser}
					onChange={(value: string) => {
						setSelectedInvoices([])
						setSelectedUser(value)
					}}>
					<div className="relative">
						<div className="relative cursor-default w-full overflow-hidden bg-white text-left border-none focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
							<Combobox.Input
								placeholder={t(tKey('placeholders.client'))}
								className="border border-border pr-7 w-full py-2 md:py-3 rounded focus:border-secondary text-sm leading-5 text-gray-900 focus:ring-0"
								displayValue={option =>
									option
										? users.find(user => user._id === selectedUser)?.fname ??
											'' + ' ' + users.find(user => user._id === selectedUser)?.lname ??
											''
										: ''
								}
								onChange={event => setQuery(event.target.value)}
							/>

							<Combobox.Button className="absolute inset-y-0 flex items-center right-0 pr-2">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none">
									<path
										d="M9.99988 12.2178C9.89945 12.2178 9.80597 12.2018 9.71944 12.1697C9.6329 12.1377 9.55064 12.0826 9.47265 12.0047L5.72746 8.25947C5.61207 8.14409 5.55305 7.99906 5.55038 7.82438C5.5477 7.6497 5.60673 7.502 5.72746 7.38128C5.84819 7.26056 5.99455 7.2002 6.16655 7.2002C6.33855 7.2002 6.48491 7.26056 6.60563 7.38128L9.99988 10.7755L13.3941 7.38128C13.5095 7.2659 13.6545 7.20687 13.8292 7.20419C14.0039 7.20153 14.1516 7.26056 14.2723 7.38128C14.393 7.502 14.4534 7.64837 14.4534 7.82038C14.4534 7.99238 14.393 8.13874 14.2723 8.25947L10.5271 12.0047C10.4491 12.0826 10.3669 12.1377 10.2803 12.1697C10.1938 12.2018 10.1003 12.2178 9.99988 12.2178Z"
										fill="#123258"
									/>
								</svg>
							</Combobox.Button>
						</div>
						<Transition
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0">
							<Combobox.Options className="absolute mt-1 z-50 max-h-60 min-w-max w-full overflow-auto rounded-md bg-white pt-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
								{Array.isArray(users) &&
									filteredOptions.map((option, index) => {
										const fname = option.fname ?? ''
										const lname = option.lname ?? ''
										return (
											<Combobox.Option
												key={index}
												className={({ active }) =>
													`relative cursor-default select-none py-0.5 ${
														active ? 'bg-[#13BAB40F] text-white' : 'text-gray-900'
													}`
												}
												value={option._id}>
												{({ selected, active }) => (
													<span className="flex gap-x-3 px-4 md:px-6 py-1.5 items-center">
														<span className="flex-col group items-start text-primary font-semibold w-full overflow-hidden text-ellipsis text-xs md:text-sm whitespace-nowrap grow hover:bg-[#13BAB40F] flex text-left rounded-md">
															{fname + ' ' + lname}

															<span className="text-tertiary text-xs">
																{option.dob &&
																	DateTime.fromMillis(Number(option.dob)).toFormat('dd.LL.yyyy')}
																{option.city && option.zipcode
																	? ` - ${option.city} - ${option.zipcode}`
																	: ''}
															</span>
														</span>
													</span>
												)}
											</Combobox.Option>
										)
									})}
							</Combobox.Options>
						</Transition>
					</div>
				</Combobox>

				<Select
					value={selectedDuration}
					className="!w-fit"
					onChange={event => {
						setSelectedInvoices([])
						setSelectedDuration(event.target.value)
					}}
					name="duration">
					<option value="">{t(tKey('labels.selectDuration'))}</option>
					<option value="week">{t(tKey('labels.week'))}</option>
					<option value="month">{t(tKey('labels.month'))}</option>
					<option value="custom">{t(tKey('labels.custom'))}</option>
				</Select>

				{selectedDuration === 'custom' && (
					<div className="flex items-center w-full gap-x-2.5">
						<Input
							type="date"
							name="from"
							value={selectedRange.from}
							max={DateTime.fromISO(selectedRange.to).endOf('day').toFormat('yyyy-LL-dd')}
							onChange={event => setSelectedRange(prev => ({ ...prev, from: event.target.value }))}
						/>
						<Input
							type="date"
							name="to"
							value={selectedRange.to}
							min={DateTime.fromISO(selectedRange.from).startOf('day').toFormat('yyyy-LL-dd')}
							onChange={event => setSelectedRange(prev => ({ ...prev, to: event.target.value }))}
						/>
					</div>
				)}
			</div>

			<Table data={filteredAppointments} columns={columns} className="table-auto" />
			<div className="flex justify-end gap-x-6 py-6">
				<button className="max-md:text-sm" type="button" onClick={onCancel}>
					{t(tKey('buttons.cancel'))}
				</button>
				<Button
					type="submit"
					disabled={isLoading || selectedInvoices.length === 0 || filteredAppointments.length === 0}
					className={clsx('px-8 py-3 max-md:text-sm max-md:py-[9px]', {
						'!bg-none !bg-gray-400':
							selectedInvoices.length === 0 || filteredAppointments.length === 0
					})}>
					{isLoading ? (
						<div className="flex items-center justify-center gap-x-5">
							<Spinner />
							<span className="animate-pulse whitespace-nowrap">
								{t(tKey('buttons.pleaseWait'))}
							</span>
						</div>
					) : (
						t(tKey('buttons.generateInvoice'))
					)}
				</Button>
			</div>
		</form>
	)
}
