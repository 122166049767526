import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer'

import { Language } from 'constants/constants'
import { DateTime } from 'luxon'

Font.register({
	family: 'Open Sans',
	fonts: [
		{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
		{
			src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
			fontWeight: 600
		},
		{
			src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf',
			fontWeight: 700
		},
		{
			src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf',
			fontWeight: 800
		}
	]
})

const styles = StyleSheet.create({
	page: {
		fontFamily: 'Open Sans',
		fontSize: 12,
		paddingHorizontal: 20,
		paddingVertical: 5
	},
	section: {
		paddingVertical: 20,
		paddingBottom: 50,
		borderBottom: '1px solid black'
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		paddingHorizontal: 10,
		alignItems: 'flex-start',
		justifyContent: 'space-between'
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		flexBasis: '50%',
		gap: 10
	}
})

interface AppointmentDocumentProps {
	detail: DocumentDetail
}

export const ApppointmentDocument = ({ detail }: AppointmentDocumentProps) => {
	const getDurationLabel = (duration: string) => {
		switch (duration) {
			case 'daily':
				return 'Dauerauftrag (täglich)'
			case 'weekly':
				return 'Dauerauftrag (wöchentlich)'
			case 'monthly':
				return 'Dauerauftrag (monatlich)'
			case 'yearly':
				return 'Dauerauftrag (jährlich)'
			default:
				return 'Einfache Fahrt'
		}
	}

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<View
					style={{
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'row',
						width: '100%',
						paddingVertical: 15,
						borderBottom: '1px solid black'
					}}>
					<Image
						src={window.location.origin + '/images/favicon-white.png'}
						style={{ height: '80px', width: '80px', marginLeft: 10 }}
					/>
					<View
						style={{
							display: 'flex',
							alignSelf: 'center',
							width: '100%',
							flexDirection: 'column',
							alignItems: 'center',
							gap: 8
						}}>
						<Text style={{ color: 'black', fontSize: '22px', fontWeight: 'bold' }}>
							Patienten-Fahrdienst Dübendorf
						</Text>
						<View
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								fontSize: '12px',
								fontWeight: 'bold',
								gap: 4
							}}>
							<Text>Auftrags-Nr.: {detail.order_number}</Text>
							<Text>|</Text>
							<Text>
								Auftrags-Datum:{' '}
								{DateTime.fromMillis(detail.order_date)
									.setLocale(Language.DE)
									.toFormat('cccc, dd. LLLL yyyy')}
							</Text>
						</View>
					</View>
				</View>

				<View style={{ ...styles.section, ...styles.row }}>
					<View style={styles.column}>
						<Text style={{ fontWeight: 'bold', fontSize: '12px' }}>
							Auftrags-Typ:{' '}
							<Text style={{ fontWeight: 600, fontSize: '10px' }}>
								{getDurationLabel(detail.duration)}
							</Text>
						</Text>
						<Text style={{ fontWeight: 'bold', fontSize: '12px' }}>
							Auftraggeber:{' '}
							<Text style={{ fontWeight: 600, fontSize: '10px' }}>{detail.passenger_name}</Text>
						</Text>
						<Text style={{ fontSize: '10px' }}>Disponent: {detail.dispatcher}</Text>
					</View>
					<View style={{ ...styles.column, marginLeft: 10 }}>
						<Text style={{ fontSize: '10px' }}>Abhol-Zeit: {detail.pickup_time}</Text>
						<Text style={{ fontSize: '10px' }}>Termin: {detail.appointment_time}</Text>
						<Text style={{ fontSize: '10px' }}>Rückfahrt ca.: {detail.return_time || 'N/A'}</Text>
						<Text style={{ fontSize: '10px' }}>Auftrags-Kosten: CHF {detail.price}</Text>
						<Text style={{ fontWeight: 'bold', fontSize: '12px' }}>
							Hin- und Rückfahrt:{' '}
							<Text style={{ fontWeight: 600, fontSize: '10px' }}>{detail.trip_type}</Text>
						</Text>
					</View>
				</View>

				<View style={{ ...styles.section, ...styles.row }}>
					<View style={styles.column}>
						<Text style={{ fontWeight: 'bold', fontSize: '12px' }}>
							Fahrgast:{' '}
							<Text style={{ fontWeight: 600, fontSize: '10px' }}>{detail.passenger_name}</Text>
						</Text>
						<Text style={{ fontWeight: 'bold', fontSize: '12px' }}>
							Abholadresse:{' '}
							<Text style={{ fontWeight: 600, fontSize: '10px' }}>{detail.pickup_address}</Text>
						</Text>
						<Text style={{ fontSize: '10px' }}>
							PLZ/Ort: {detail.pickup_zip_code} {detail.pickup_city}
						</Text>
						<Text style={{ fontSize: '10px' }}>Telefon: {detail.passenger_phone || 'N/A'}</Text>
						<Text style={{ fontSize: '10px' }}>Mobile: {detail.passenger_mobile || 'N/A'}</Text>
						<Text style={{ fontSize: '10px' }}>Behinderung: {detail.disability || 'Keine'}</Text>
						<Text style={{ fontSize: '10px' }}>Bemerkungen: {detail.remarks || 'Keine'}</Text>
					</View>
					<View style={{ ...styles.column, marginLeft: 10 }}>
						<Text style={{ fontWeight: 'bold', fontSize: '12px' }}>
							Ziel-Name:{' '}
							<Text style={{ fontWeight: 600, fontSize: '10px' }}>{detail.destination_name}</Text>
						</Text>
						<Text style={{ fontSize: '10px' }}>Ziel-Adresse: {detail.destination_address}</Text>
						<Text style={{ fontSize: '10px' }}>
							Ziel-Ort: {detail.destination_city} {detail.destination_zip_code}
						</Text>
						<Text style={{ fontSize: '10px' }}>Telefon: {detail.destination_phone}</Text>
						<Text style={{ fontSize: '10px' }}>Distanz (km): {detail.distance}</Text>
					</View>
				</View>

				<View style={{ paddingVertical: 20, paddingBottom: 50 }}>
					<View style={{ ...styles.column, marginLeft: 10 }}>
						<Text style={{ fontWeight: 'bold', fontSize: '12px' }}>
							Fahrer:{' '}
							<Text style={{ fontWeight: 600, fontSize: '10px' }}>{detail.driver_name}</Text>
						</Text>
						<Text style={{ fontSize: '10px' }}>Adresse: {detail.driver_address}</Text>
						<Text style={{ fontSize: '10px' }}>
							PLZ/Wohnort: {detail.destination_zip_code} {detail.driver_city}
						</Text>
						<Text style={{ fontSize: '10px' }}>Mobile: {detail.driver_phone}</Text>
						<Text style={{ fontSize: '10px' }}>E-Mail-Adresse: {detail.driver_email}</Text>
					</View>
				</View>
			</Page>
		</Document>
	)
}
