import { Combobox, Transition } from '@headlessui/react'
import { Tooltip as ReactTooltip } from 'flowbite-react'
import { Fragment, useEffect, useMemo, useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import clsx from 'clsx'
import { DateTime } from 'luxon'
import {
	Area,
	AreaChart,
	CartesianGrid,
	Cell,
	Label,
	Pie,
	PieChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts'

import { BlockIcon, DirectionIcon, DollarIcon, ExpandIcon } from 'assets/icons'
import { ReactComponent as CostIcon } from 'assets/svgs/cost.svg'
import { ReactComponent as DistanceIcon } from 'assets/svgs/distance.svg'
import { ReactComponent as GlobeIcon } from 'assets/svgs/globe.svg'
import { ReactComponent as LocationIcon } from 'assets/svgs/location.svg'
import { ReactComponent as PercentageIcon } from 'assets/svgs/percentage.svg'
import { AppLayout } from 'components/app/layout'
import { MenuDropdown } from 'components/app/menu'
import { AppointmentStatus, mappedAppointmentStatuses } from 'constants/constants'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import appointmentService from 'services/appointment-service'
import destinationService from 'services/destination-service'
import { getYears } from 'utils/date'
import { getAppLang, getTKey } from 'utils/language'

export const Dashboard = () => {
	const { i18n, t } = useTranslation()
	const isMobile = useIsMobile()
	const navigate = useNavigate()

	const tKey = getTKey('dashboard')
	const yearList = getYears()

	const auth = useAppSelector(state => state.auth)

	const [appointmentStats, setAppointmentStats] = useState<
		{ title: string; value: number; link: string }[]
	>([])
	const [companyAppointments, setCompanyAppointments] = useState<Appointment[]>([])
	const [allAppointments, setAllAppointments] = useState<Appointment[]>([])
	const [calendarValue, setCalendarValue] = useState<CalendarValue>(DateTime.now().toJSDate())
	const [destinationStats, setDestinationStats] = useState<DestinationStat[]>([])
	const [selectedRange, setSelectedRange] = useState('monthly')
	const [selectedDuration, setSelectedDuration] = useState(DateTime.now().toFormat('yyyy'))
	const [query, setQuery] = useState('')
	const [weeklyRevenue, setWeeklyRevenue] = useState<WeeklyRevenue[]>([])
	const [monthlyRevenue, setMonthlyRevenue] = useState<MonthlyRevenue[]>([])

	useEffect(() => {
		if (auth.is_adminUser === true) {
			appointmentService.getCompanyAppointments(auth.companyId).then(res => setAllAppointments(res))
		} else {
			appointmentService.getCompanyAppointments(auth.companyId).then(res => setAllAppointments(res))
		}
	}, [auth])

	useEffect(() => {
		destinationService.getDestinationStats().then(res => setDestinationStats(res))
		appointmentService.getAppointmentStats().then(res =>
			setAppointmentStats([
				{
					title: t(tKey('stats.labels.totalAppointments')),
					value: res?.totalAppointment as number,
					link: '/bookings'
				},
				{
					title: t(tKey('stats.labels.pendingAppointments')),
					value: res?.pending as number,
					link: `/bookings?status=${mappedAppointmentStatuses[AppointmentStatus.PENDENT]}`
				},
				{
					title: t(tKey('stats.labels.cancelledAppointments')),
					value: res?.cancelled as number,
					link: `/bookings?status=${mappedAppointmentStatuses[AppointmentStatus.CANCELLED]}`
				},
				{
					title: t(tKey('stats.labels.completeAppointments')),
					value: res?.completed as number,
					link: `/bookings?status=${mappedAppointmentStatuses[AppointmentStatus.COMPLETED]}`
				},
				{
					title: t(tKey('stats.labels.confirmedAppointments')),
					value: res?.confirmed as number,
					link: `/bookings?status=${mappedAppointmentStatuses[AppointmentStatus.CONFIRMED]}`
				}
			])
		)
		if (auth.is_adminUser === true) {
			appointmentService
				.getCompanyAppointments(auth.companyId)
				.then(res => setCompanyAppointments(res))
		} else {
			appointmentService
				.getCompanyAppointments(auth.companyId)
				.then(res => setCompanyAppointments(res))
		}
	}, [])

	useEffect(() => {
		if (selectedRange === 'monthly') {
			appointmentService.getRevenueStatsByWeek().then(data => setWeeklyRevenue(data))
		} else {
			if (selectedDuration) {
				appointmentService
					.getRevenueStatsByYear(Number(selectedDuration))
					.then(data => setMonthlyRevenue(data))
			}
		}
	}, [selectedDuration, selectedRange])

	const filteredOptions = useMemo(() => {
		if (yearList) {
			return yearList.filter(year => {
				return (
					query === '' || year.replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
				)
			})
		} else {
			return yearList
		}
	}, [yearList, query])

	const data = [
		{ name: appointmentStats[3]?.title, value: appointmentStats[3]?.value },
		{ name: appointmentStats[1]?.title, value: appointmentStats[1]?.value },
		{ name: appointmentStats[2]?.title, value: appointmentStats[2]?.value },
		{ name: appointmentStats[4]?.title, value: appointmentStats[4]?.value }
	]
	const COLORS = ['#18DF80', '#F5B715', '#E74A29', '#00bcd4']

	return (
		<AppLayout title={t(tKey('titles.dashboard'))} isHeaderVisible={!isMobile}>
			<div className="flex max-md:overflow-x-hidden flex-col bg-white md:gap-6 max-md:p-5 md:px-6 md:py-6">
				<div className="flex max-md:flex-col-reverse max-md:gap-y-3 items-center justify-between gap-x-6">
					<div className="bg-stats bg-cover overflow-hidden bg-center bg-no-repeat flex flex-col basis-full md:basis-[75%] w-full rounded-xl pt-3 pb-6 px-6 gap-3 md:gap-5">
						<div className="flex items-start gap-x-2 justify-between w-full">
							<div className="flex w-full flex-col md:basis-[60%] gap-y-5">
								<h1 className="font-domine text-lg text-white font-bold">
									{t(tKey('titles.totalTrips'))}
								</h1>
								<div className="flex flex-col w-full gap-y-[22px]">
									<div className="flex max-md:flex-col w-full gap-[22px] items-center">
										<div className="flex w-full max-md:flex-col gap-y-[22px] items-center gap-x-3">
											<div className="flex w-full items-center gap-x-3">
												<div className="flex w-full flex-col">
													<h4 className="text-white font-medium">{t(tKey('labels.completed'))}</h4>
													<div className="overflow-hidden rounded-full bg-[#FFFFFF4D]">
														<div
															style={{
																width: `${
																	(appointmentStats[3]?.value / (appointmentStats[0]?.value || 1)) *
																	100
																}%`
															}}
															className="h-2 rounded-full bg-[#18DF80]"
														/>
													</div>
												</div>
												<h2 className="text-[#18DF80] font-domine text-2xl">
													{appointmentStats[3]?.value}
												</h2>
												<div className="size-6 rounded-full flex items-center justify-center bg-[#FFFFFFE5] p-0.5">
													<p className="text-[#123258] text-[10px] font-medium">
														{Math.round(
															(appointmentStats[3]?.value / (appointmentStats[0]?.value || 1)) * 100
														)}
														%
													</p>
												</div>
											</div>
											<div className="flex w-full items-center gap-x-3">
												<div className="flex w-full flex-col">
													<h4 className="text-white font-medium">{t(tKey('labels.cancelled'))}</h4>
													<div className="overflow-hidden rounded-full bg-[#FFFFFF4D]">
														<div
															style={{
																width: `${
																	(appointmentStats[2]?.value / (appointmentStats[0]?.value || 1)) *
																	100
																}%`
															}}
															className="h-2 rounded-full bg-[#E74A29]"
														/>
													</div>
												</div>
												<h2 className="text-[#E74A29] font-domine text-2xl">
													{appointmentStats[2]?.value}
												</h2>
												<div className="size-6 rounded-full flex items-center justify-center bg-[#FFFFFFE5] p-0.5">
													<p className="text-[#123258] text-[10px] font-medium">
														{Math.round(
															(appointmentStats[2]?.value / (appointmentStats[0]?.value || 1)) * 100
														)}
														%
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="flex  max-md:flex-col w-full gap-[22px] items-center">
										<div className="flex w-full items-center gap-x-3">
											<div className="flex w-full flex-col">
												<h4 className="text-white font-medium">{t(tKey('labels.pending'))}</h4>
												<div className="overflow-hidden rounded-full bg-[#FFFFFF4D]">
													<div
														style={{
															width: `${
																(appointmentStats[1]?.value / (appointmentStats[0]?.value || 1)) *
																100
															}%`
														}}
														className="h-2 rounded-full bg-[#F5B715]"
													/>
												</div>
											</div>
											<h2 className="text-[#F5B715] font-domine text-2xl">
												{appointmentStats[1]?.value}
											</h2>
											<div className="size-6 rounded-full flex items-center justify-center bg-[#FFFFFFE5] p-0.5">
												<p className="text-[#123258] text-[10px] font-medium">
													{Math.round(
														(appointmentStats[1]?.value / (appointmentStats[0]?.value || 1)) * 100
													)}
													%
												</p>
											</div>
										</div>
										<div className="flex w-full items-center gap-x-3">
											<div className="flex w-full flex-col">
												<h4 className="text-white font-medium">{t(tKey('labels.confirmed'))}</h4>
												<div className="overflow-hidden rounded-full bg-[#FFFFFF4D]">
													<div
														style={{
															width: `${
																(appointmentStats[4]?.value / (appointmentStats[0]?.value || 1)) *
																100
															}%`
														}}
														className="h-2 rounded-full bg-cyan-500"
													/>
												</div>
											</div>
											<h2 className="text-cyan-500 font-domine text-2xl">
												{appointmentStats[4]?.value}
											</h2>
											<div className="size-6 rounded-full flex items-center justify-center bg-[#FFFFFFE5] p-0.5">
												<p className="text-[#123258] text-[10px] font-medium">
													{Math.round(
														(appointmentStats[4]?.value / (appointmentStats[0]?.value || 1)) * 100
													)}
													%
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							{!isMobile && (
								<PieChart width={isMobile ? 120 : 152} height={isMobile ? 120 : 152}>
									<Pie
										data={data}
										cx={isMobile ? 60 : 71}
										cy={isMobile ? 60 : 71}
										innerRadius={isMobile ? 45 : 45}
										outerRadius={isMobile ? 55 : 60}
										fill="#8884d8"
										paddingAngle={5}
										dataKey="value">
										{data.map((entry, index) => (
											<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
										))}
										<Label
											value={appointmentStats[0]?.value}
											position="center"
											fill="#FFFFFF"
											style={
												isMobile
													? { fontSize: '14px', fontWeight: 600 }
													: { fontSize: '19px', fontWeight: 600 }
											}
										/>
									</Pie>
								</PieChart>
							)}
						</div>
					</div>

					<div
						style={{ boxShadow: '0px 4px 24px 0px #CFD8DC4D' }}
						className="md:basis-[25%] rounded-xl py-4 max-md:pl-6 max-md:pr-0 md:px-6 bg-[#2668C4] basis-full border border-[#CFD8DC80] flex md:flex-col gap-y-8">
						<div className="md:h-[136px] overflow-hidden flex flex-col">
							<h1 className="font-domine max-md:text-sm font-bold text-white leading-5">
								{t(tKey('labels.welcome'))} {auth?.fname + ' ' + auth?.lname}
							</h1>
							<p className="md:text-sm text-xs mt-4 text-white">{t(tKey('labels.uniqueBlocks'))}</p>
							<img
								src={BlockIcon}
								className="-rotate-4 max-md:hidden ml-auto -mt-7 object-contain size-36"
							/>
						</div>
						<img
							src={BlockIcon}
							className="-rotate-4 md:hidden ml-auto -mt-10 object-contain size-[130px]"
						/>
					</div>
				</div>

				<div className="flex max-md:flex-col max-md:mt-3 md:items-center md:justify-between gap-y-3 md:gap-x-6">
					<div className="md:basis-[75%] grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 items-start">
						<div className="border w-full bg-white border-[#CFD8DC] flex flex-col gap-3 md:gap-4 rounded-xl">
							<div className="flex h-8 py-7 md:py-3 px-3 bg-[#191919] items-center justify-between">
								<div className="flex items-center gap-1.5">
									<img src={DollarIcon} className="max-md:size-[18px]" />
									<h1 className="font-domine font-bold text-white text-sm">
										{t(tKey('titles.totalRevenue'))}
									</h1>
								</div>
								<div className="flex gap-1 md:gap-2 items-center">
									<MenuDropdown
										options={[t(tKey('menu.month')), t(tKey('menu.year'))]}
										value={
											selectedRange === 'monthly' ? t(tKey('menu.month')) : t(tKey('menu.year'))
										}
										shouldIgnore
										className="!left-0"
										onChange={(value: string) => {
											if (value === t(tKey('menu.month'))) {
												setSelectedRange('monthly')
											} else {
												setSelectedRange('yearly')
											}
										}}
									/>
									{selectedRange === 'yearly' && (
										<Combobox
											value={selectedDuration}
											onChange={(value: string) => setSelectedDuration(value)}>
											<div className="relative">
												<div className="relative cursor-default w-20 md:w-24 overflow-hidden bg-white rounded-lg text-left border-none focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
													<Combobox.Input
														placeholder={t(tKey('placeholders.year'))}
														className="border border-border bg-[#FFFFFF99] text-xs pr-7 w-full !py-[3px] !md:py-[3px] rounded focus:border-secondary leading-5 text-gray-900 focus:ring-0"
														displayValue={option => selectedDuration}
														onChange={event => setQuery(event.target.value)}
													/>

													<Combobox.Button className="absolute inset-y-0 flex items-center right-0 pr-2">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="20"
															height="20"
															viewBox="0 0 20 20"
															fill="none">
															<path
																d="M9.99988 12.2178C9.89945 12.2178 9.80597 12.2018 9.71944 12.1697C9.6329 12.1377 9.55064 12.0826 9.47265 12.0047L5.72746 8.25947C5.61207 8.14409 5.55305 7.99906 5.55038 7.82438C5.5477 7.6497 5.60673 7.502 5.72746 7.38128C5.84819 7.26056 5.99455 7.2002 6.16655 7.2002C6.33855 7.2002 6.48491 7.26056 6.60563 7.38128L9.99988 10.7755L13.3941 7.38128C13.5095 7.2659 13.6545 7.20687 13.8292 7.20419C14.0039 7.20153 14.1516 7.26056 14.2723 7.38128C14.393 7.502 14.4534 7.64837 14.4534 7.82038C14.4534 7.99238 14.393 8.13874 14.2723 8.25947L10.5271 12.0047C10.4491 12.0826 10.3669 12.1377 10.2803 12.1697C10.1938 12.2018 10.1003 12.2178 9.99988 12.2178Z"
																fill="#123258"
															/>
														</svg>
													</Combobox.Button>
												</div>
												<Transition
													as={Fragment}
													leave="transition ease-in duration-100"
													leaveFrom="opacity-100"
													leaveTo="opacity-0">
													<Combobox.Options className="absolute mt-1 z-50 max-h-60 min-w-max w-full overflow-auto rounded-md bg-white pt-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
														{Array.isArray(getYears()) &&
															filteredOptions.map((option, index) => (
																<Combobox.Option
																	key={index}
																	className={({ active }) =>
																		`relative cursor-default select-none py-0.5 ${
																			active ? 'bg-[#13BAB40F] text-white' : 'text-gray-900'
																		}`
																	}
																	value={option}>
																	{({ selected, active }) => (
																		<span className="flex gap-x-3 px-4 md:px-6 py-1.5 items-center">
																			<span className="flex-col group items-start text-primary font-semibold w-full overflow-hidden text-ellipsis text-xs md:text-sm whitespace-nowrap grow hover:bg-[#13BAB40F] flex text-left rounded-md">
																				{option}
																			</span>
																		</span>
																	)}
																</Combobox.Option>
															))}
													</Combobox.Options>
												</Transition>
											</div>
										</Combobox>
									)}
								</div>
							</div>
							<h1 className="text-[#E74A29] w-full ml-7 font-domine font-bold md:text-[18px]">
								{selectedRange === 'monthly'
									? weeklyRevenue?.reduce((agg, curr) => agg + curr.totalTrips, 0)
									: monthlyRevenue?.reduce((agg, curr) => agg + curr.totalTrips, 0)}
							</h1>
							<ResponsiveContainer width="100%" height={isMobile ? 200 : 300}>
								<AreaChart
									width={600}
									height={300}
									data={
										selectedRange === 'monthly'
											? weeklyRevenue.map(revenue => ({
													name: revenue.dayOfWeek,
													['Total Trips']: revenue.totalTrips
												}))
											: monthlyRevenue.map(revenue => ({
													name: revenue.month,
													['Total Trips']: revenue.totalTrips
												}))
									}
									margin={{
										top: 10,
										right: 30,
										left: 0,
										bottom: 0
									}}>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="name" />
									<YAxis />
									<Tooltip />
									<Area type="monotone" dataKey="Total Trips" stroke="#E74A29" fill="#E74A2924" />
								</AreaChart>
							</ResponsiveContainer>
						</div>

						<div className="border self-stretch w-full bg-white border-[#CFD8DC] flex flex-col gap-3 md:gap-4 rounded-xl">
							<div className="flex h-8 py-3 px-3 bg-black items-center justify-between">
								<div className="flex items-center gap-1.5">
									<img src={DirectionIcon} className="max-md:size-[18px]" />
									<h1 className="font-domine font-bold text-white text-sm">
										{t(tKey('titles.upcomingAppointments'))}
									</h1>
								</div>
								<img
									src={ExpandIcon}
									onClick={() => navigate('/bookings')}
									className="cursor-pointer max-md:size-[18px]"
								/>
							</div>
							<div className="flex py-0.5 px-4 pb-2 flex-col gap-3">
								{companyAppointments.filter(
									appointment =>
										DateTime.fromMillis(appointment.appointment_time) > DateTime.now() &&
										appointment.status !== AppointmentStatus.CANCELLED
								)?.length > 0 ? (
									companyAppointments
										.filter(
											appointment =>
												DateTime.fromMillis(appointment.appointment_time) > DateTime.now()
										)
										.slice(0, 3)
										.sort((a, b) => a.appointment_time - b.appointment_time)
										.map((appointment, index) => (
											<div
												key={appointment._id}
												style={{ boxShadow: '0px 4px 24px 0px #CFD8DCBD' }}
												onClick={() => navigate(`/booking-details/${appointment._id}`)}
												className={clsx(
													'border cursor-pointer rounded-lg p-1 flex items-center gap-3',
													index % 2 === 0 ? 'bg-white' : 'bg-white'
												)}>
												<div className="flex items-center gap-x-4">
													<time className="bg-[#F5B71533] p-2 rounded flex flex-col items-center gap-[3px]">
														<p className="font-domine font-bold text-[#123258] text-sm">
															{DateTime.fromMillis(appointment.appointment_time)
																.setLocale(getAppLang())
																.toFormat('dd')}
														</p>
														<p className="text-[#123258] font-semibold text-[11px]">
															{DateTime.fromMillis(appointment.appointment_time)
																.setLocale(getAppLang())
																.toFormat('LLL,yyyy')}
														</p>
													</time>
													<div className="flex flex-col gap-0.5">
														<ReactTooltip
															placement="bottom"
															style="dark"
															content={appointment.destination.destination_name}
															className="whitespace-nowrap text-white bg-[#323232] text-sm">
															<h2 className="text-[#123258] line-clamp-1 text-ellipsis font-semibold">
																{appointment.destination.destination_name}
															</h2>
														</ReactTooltip>
														<div className="flex items-center gap-4">
															<div className="flex items-center gap-2">
																<DistanceIcon />
																<p className="text-[#4C6780] text-xs">
																	{appointment.destination.distance} km
																</p>
															</div>
															<div className="flex items-center gap-2">
																<CostIcon className="size-4" />
																<p className="text-[#4C6780] text-xs">
																	CHF {appointment.destination.cost.toFixed(2)}
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										))
								) : (
									<p className="text-tertiary text-sm">{t(tKey('noAppointments'))}</p>
								)}
							</div>
						</div>
					</div>
					<div className="max-md:hidden self-stretch basis-[25%] flex flex-col gap-y-8">
						<Calendar
							className="rounded-2xl h-full w-fit p-4 calendar-icon"
							onChange={setCalendarValue}
							selectRange={false}
							goToRangeStartOnSelect={false}
							next2Label={null}
							prev2Label={null}
							tileDisabled={() => selectedRange === 'allTime'}
							tileClassName={({ date }) => {
								const currentDate = DateTime.fromJSDate(date)
								const isSelected = DateTime.fromJSDate(calendarValue as any)?.hasSame(
									currentDate,
									'day'
								)

								if (isSelected) {
									return 'text-white focus:bg-[#F5B715] bg-[#F5B715] rounded-full font-medium'
								}

								return 'text-gray-700'
							}}
							tileContent={({ date }) => {
								const currentDate = DateTime.fromJSDate(date)
								if (
									allAppointments.some(appointment =>
										DateTime.fromMillis(appointment.appointment_time).hasSame(currentDate, 'day')
									)
								) {
									return <div className="mx-auto w-1 h-1 bg-[#F5B715] rounded-full" />
								}
								return null
							}}
							locale={i18n.language}
							value={calendarValue}
							formatShortWeekday={(locale, date) =>
								date.toLocaleDateString(locale, { weekday: 'short' })
							}
						/>
					</div>
				</div>

				<div className="flex max-md:flex-col max-md:mt-3 items-center justify-between gap-3 md:gap-x-6">
					<div className="md:basis-full self-stretch grid grid-cols-1 md:grid-cols-1 gap-3 md:gap-6 items-start">
						<div className="border w-full self-stretch bg-white border-[#CFD8DC] flex flex-col gap-3 md:gap-4 rounded-xl">
							<div className="flex h-8 py-0 px-3 bg-[#191919] items-center w-full justify-between">
								<div className="flex items-center gap-1.5">
									<GlobeIcon className="max-md:size-[18px]" />
									<h1 className="font-domine font-bold text-white text-sm">
										{t(tKey('titles.popularDestinations'))}
									</h1>
								</div>
								<img
									src={ExpandIcon}
									className="cursor-pointer max-md:size-[18px]"
									onClick={() => navigate('/destinations')}
								/>
							</div>
							<div className="flex flex-col px-4 pb-2 gap-3">
								{destinationStats.length > 0 ? (
									destinationStats
										?.sort((a, b) => b.totalRevenue - a.totalRevenue)
										.slice(0, 3)
										.map(destination => {
											return (
												<div
													style={{ boxShadow: '0px 4px 24px 0px #CFD8DCBD' }}
													onClick={() => navigate('/destinations', { state: destination.id })}
													className="rounded-lg cursor-pointer py-3 px-2 md:py-1 md:px-2 flex max-md:flex-col max-md:gap-y-2.5 md:justify-between md:items-center"
													key={destination.id}>
													<div className="flex items-center gap-3">
														<div
															style={{
																backgroundColor: destination.destination_type_color
															}}
															className="size-4 rounded-[2px]"
														/>
														<div className="flex flex-col gap-2.5 md:gap-0.5">
															<h1 className="text-primary whitespace-nowrap overflow-hidden text-ellipsis max-md:text-xs font-semibold">
																{destination.destination_type_name}
															</h1>
															<div className="flex items-center gap-4">
																<div className="flex items-center gap-2">
																	<LocationIcon />
																	<p className="text-[#4C6780] text-xs">
																		{destination.destination_name}
																	</p>
																</div>
																<div className="flex items-center gap-2">
																	<DistanceIcon />
																	<p className="text-[#4C6780] text-xs">
																		{destination.distance} km
																	</p>
																</div>
															</div>
														</div>
													</div>
													<div className="flex items-center max-md:mx-auto gap-3">
														<div className="flex items-center gap-1">
															<p className="text-[#757575] text-xs font-medium">
																{t(tKey('labels.bookings'))}:
															</p>
															<p className="text-[#000000CC] max-md:text-xs font-medium ">
																{destination.bookingsCount}
															</p>
														</div>
														<div className="flex items-center gap-1">
															<p className="text-[#757575] text-xs font-medium">
																{t(tKey('labels.revenue'))}:
															</p>
															<p className="text-[#E74A29] max-md:text-xs font-medium">
																{destination.currency} {destination.totalRevenue.toFixed(2)}
															</p>
														</div>
														<div className="flex items-center gap-1">
															<PercentageIcon />
															<p className="text-xs font-medium text-[#000000CC]">
																{destination.percentage}%
															</p>
														</div>
													</div>
												</div>
											)
										})
								) : (
									<p className="text-tertiary text-sm">{t(tKey('labels.noDestinations'))}</p>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</AppLayout>
	)
}
